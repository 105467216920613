var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"userAchievements",attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[_c('v-card',[_c('v-card-title',[_vm._v(" إنجازات المستخدمين "),_c('v-spacer'),_c('v-row',{staticClass:"pt-5"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-x":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"id":"searchDates","label":"تحديد المدة الزمنية","placeholder":"YYYY-MM-DD Format","hint":"YYYY-MM-DD Format","outlined":""},on:{"input":_vm.fetchAllItems},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.searchDates),callback:function ($$v) {_vm.searchDates=$$v},expression:"searchDates"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":_vm.fetchAllItems},model:{value:(_vm.search.dates),callback:function ($$v) {_vm.$set(_vm.search, "dates", $$v)},expression:"search.dates"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.status,"item-text":"name","item-value":"id","label":"المراحل","outlined":""},on:{"change":_vm.fetchAllItems},model:{value:(_vm.search.status),callback:function ($$v) {_vm.$set(_vm.search, "status", $$v)},expression:"search.status"}})],1)],1)],1),_c('v-data-table',{staticClass:"custom_table_class mt-8",attrs:{"loading":_vm.dataLoading,"headers":_vm.headers,"search":_vm.search,"items":_vm.items,"items-per-page":20,"footer-props":{
        'items-per-page-options': [5, 10, 20, 40, 50],
      }},on:{"fetchAllItems":_vm.fetchAllItems},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-avatar',{attrs:{"size":"40"}},[_c('img',{attrs:{"src":item.image,"alt":"profile image"}})])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('p',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',[_vm._v(_vm._s(item.email))])])],1)]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("لا يوجد بيانات")])]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }